import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Icons from "../../misc/Icons";
import DeviceFrame from "../../misc/DeviceFrame";


// Data
const DemoStepsData = {
  leadTitle: "Ismerje meg",
  title: "Téradataink új lehetőségeket kínálnak munkafolyamatai optimalizálására",
  steps: [
    {
      title: "Címek keresése",
      content: "Címkeresőnk segítségével könnyen megtalálhatja a felmérni kívánt épületeket.",
    },
    {
      title: "Téradatok igénylése",
      content: "A felmérni kívánt épületekre kattintva azonnal láthatja annak elérhetőségét és saját szükségletei alapján választhat elérhető termékcsomagaink közül.",
    },
    {
      title: "Online 3D nézet",
      content: "A letölthető fájlok mellett könnyedén, online is megtekintheti téradait 3D megjelenítőnkben bármilyen eszközön.",
    },
    {
      title: "Precíz eszközök",
      content: "Megjelenítőnk eszközeit használva részletes adatokat nyerhet ki, valamint méréseket végezhet specifikus szoftverek nélkül.",
    }
  ]
};

// Component
const DemoSteps = () => {
  const images = useStaticQuery(graphql`
    query demosteps {
      mobile: allFile (
        filter: {
          absolutePath: { regex: "/(demosteps/mobile)/" }
          sourceInstanceName: { ne: "markdown" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                placeholder: BLURRED
                formats: WEBP
                breakpoints: [378]
              )
            }
            name
            sourceInstanceName
          }
        }
      }
      tablet: allFile (
        filter: {
          absolutePath: { regex: "/(demosteps/tablet)/" }
          sourceInstanceName: { ne: "markdown" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                placeholder: BLURRED
                formats: WEBP
                breakpoints: [672, 980, 1200, 1568]
              )
            }
            name
            sourceInstanceName
          }
        }
      }
    }
  `);
  const demostepMobiletImg = images.mobile;
  const demostepTabletImg = images.tablet;

  const [selected, setSelected] = useState(0);
  const stepsLength = DemoStepsData.steps.length;

  const toggleStep = (i) => {
    if (selected !== i) {
      setSelected(i);
    }
  };

  return (
    <section className="--demo-steps-section">
      
      <div className="--demo-steps-section__heading">
        <h4>{DemoStepsData.leadTitle}</h4>
        <h2>{DemoStepsData.title}</h2>
      </div>

      <div className="--demo-steps-section__steps">

        <div className="--demo-steps-section__steps-stepper">
          {DemoStepsData.steps.map((item, i) => {
            return (
              <div className="stepper-item" key={i}>
                <div className={"stepper-item__title " + (selected === i ? "active" : "")}>
                  
                  <button onClick={() => toggleStep(i)}>
                    {selected === i ? (
                      <Icons.Substract color="green" />
                    ) : (
                      <Icons.Add color="green" />
                    )}
                    <h3>{item.title}</h3>
                  </button>

                  <div className="stepper-item__text">
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      
        <div className="--demo-steps-section__steps-content">
          
          <div className="--demo-steps-section__steps-content__pager">
            <button
              className={selected === 0 ? "hidden" : ""}
              onClick={() => toggleStep(selected - 1)}
            >
              <Icons.ArrowBackward/>
            </button>
            <button
              className={selected === stepsLength-1 ? "hidden" : ""}
              onClick={() => toggleStep(selected + 1)}
            >
              <Icons.ArrowForward/>
            </button>
          </div>

          
          {DemoStepsData.steps.map((item, index) => (
            <div
              key={index}
              className={"--demo-steps-section__steps-content__image " + (selected === index ? "active" : "")}
            >
              <DeviceFrame fit>
                <GatsbyImage
                  className="child child__mobile"
                  image={getImage(
                    demostepMobiletImg.edges[index].node.childrenImageSharp[0].gatsbyImageData
                  )}
                  loading={"lazy"}
                  alt={item.title}
                />
                <GatsbyImage
                  className="child child__tablet"
                  image={getImage(
                    demostepTabletImg.edges[index].node.childrenImageSharp[0].gatsbyImageData
                  )}
                  loading={"lazy"}
                  alt={item.title}
                />
              </DeviceFrame>
            </div>
          ))}
        </div>

      </div>
    </section>
  )
};

export default DemoSteps;
