import React, { useState, useRef, useEffect } from "react";
import Icons from "../../misc/Icons";

// Data
const FaqData = {
  title: "Gyakori kérdések",
  items: [
    {
      question: "Kipróbálhatom a szolgáltatást mielőtt elköteleződnék?",
      answer: "Igen, regisztrációt követően 1 hónapig díjmentesen, teljeskörűen használható az online felület, ezen időszak alatt 3 db lakóingatlan épületmodelljének díjmentes lekérdezésére van lehetősége a napelemes rendszerek kivitelezésével, telepítésével foglalkozó regisztrált felhasználóknak.",
    },
    {
      question: "Hogyan tudok regisztrálni a szolgáltatásra?",
      answer: "Az oldal jobb felső sarkában található „PRÓBÁLJA KI INGYEN” szövegre kattintva megjelenik a regisztrációs felület. A szükséges adatok megadása, majd elküldése után elindul a regisztrációs folyamat. A felhasználói fiókok végleges regisztrációját minden esetben az oldal üzemeltetője, a Solar ViewPoint Kft. végzi el.",
    },
    {
      question: "Cégen belül többen is használhatjuk a szolgáltatást azonos fiókkal?",
      answer: "Igen, egy regisztrációs fiókhoz korlátlan számú felhasználó rendelhető.",
    },
    {
      question: "Azonnal elérem az épületmodelleket?",
      answer: "Az épületmodell a Solar ViewPoint Kft. online felületén történő megrendelés visszaigazolásától számított 3 munkanapon belül készül el.",
    },
    {
      question: "Meddig férhet hozzá a megvásárolt adatokhoz?",
      answer: "Az elkészült épületmodell termékek a megrendelés teljesítésétől számított 6 hónapig elérhetőek a solarvp.hu felületen. Ez idő alatt lehetőség van az elkészült fájlok és dokumentumok letöltésére is.",
    },
  ]
};

// Component
const Faq = () => {
  return (
    <section className="--faq-section">
      <div className="--faq-section__container">
        <h2>{FaqData.title}</h2>
        <div className="--faq-section__items">
          {FaqData.items.map((item, i) => {
            return (
              <FaqItem key={i} title={item.question} content={item.answer}/>
            )
          })}
        </div>
      </div>
    </section>
  )
};

const FaqItem = (props) => {
  const [active, toogleActive] = useState(false)
  const [height, setHeight] = useState("0px")
  const content = useRef()

  useEffect(() => {
    setHeight(active ? `${content.current.scrollHeight}px`: "0px")
  }, [active])

  return (
    <div className="faq-item">
      <button
        className={"faq-item__head " + (active ? "active" : "")}
        onClick={() => toogleActive(!active)}
      >
        <p className="faq-item__head__title">
          {props.title}
        </p>
        <div className="faq-item__head__icon">
          <Icons.Chevron color="green"/>
        </div>
      </button>
      <div
        ref={content}
        className="faq-item__content"
        style={{ maxHeight: `${height}` }}
      >
        <div
          className="faq-item__content__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  )
};

export default Faq;