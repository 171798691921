import React from "react"

// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

let fillColor
const ColorSwitch = propColor => {
  switch (propColor) {
    case "green":
      fillColor = "#00FF7F"
      break
    case "white":
      fillColor = "#FFFFFF"
      break
    case "notify":
      fillColor = "#FF0055"
      break
    default:
      fillColor = "#0A0A29"
      break
  }
  return fillColor
}

// Icons
//
// To add an icon, create an arrow function with the name of the icon,
// give it the input value "props", call the ColorSwitch function at
// the beginning with the value "props.color" and return the svg code.

const Add = props => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M21 11.001h-8v-8h-2v8H3v2h8v8h2v-8h8v-2z"/>
      </g>
    </svg>
  )
}

const ArrowDown = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"/>
      </g>
    </svg>
  )
}

const ArrowBackward = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"/>
      </g>
    </svg>
  )
}

const ArrowForward = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M12 4L10.5867 5.41333L16.1733 11H4V13H16.1733L10.5867 18.5867L12 20L20 12L12 4Z"/>
      </g>
    </svg>
  )
}

const Cookie = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M17 3C17 3.55228 16.5523 4 16 4C15.4477 4 15 3.55228 15 3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 10.346 6.346 9 8 9C9.654 9 11 10.346 11 12C11 13.654 9.654 15 8 15C6.346 15 5 13.654 5 12ZM7 12C7 12.551 7.449 13 8 13C8.551 13 9 12.551 9 12C9 11.449 8.551 11 8 11C7.449 11 7 11.449 7 12Z"
      />
      <path
        fill={fillColor}
        d="M16.001 15C16.5533 15 17.001 14.5523 17.001 14C17.001 13.4477 16.5533 13 16.001 13C15.4487 13 15.001 13.4477 15.001 14C15.001 14.5523 15.4487 15 16.001 15Z"
      />
      <path
        fill={fillColor}
        d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.61 9.89105C20.892 9.80005 21.201 9.83905 21.453 9.99805C21.704 10.1571 21.871 10.4191 21.908 10.7151C21.971 11.1991 22 11.6071 22 12.0001C22 17.5151 17.514 22.0001 12 22.0001C6.486 22.0001 2 17.5151 2 12.0001C2 6.67505 6.169 2.29205 11.492 2.02505C11.857 2.00405 12.203 2.18905 12.394 2.49905C12.586 2.81105 12.592 3.20205 12.41 3.51905C12.139 3.99505 12 4.49305 12 5.00005C12 6.65405 13.346 8.00005 15 8.00005C15.531 8.00005 16.045 7.85305 16.529 7.56405C16.817 7.39205 17.171 7.37505 17.474 7.52005C17.776 7.66405 17.986 7.95105 18.033 8.28205C18.172 9.26105 19.018 10.0001 20 10.0001C20.184 10.0001 20.377 9.96505 20.61 9.89105ZM4 12.0001C4 16.4111 7.589 20.0001 12 20.0001C16.411 20.0001 20 16.4111 20 12.0001C18.464 12.0001 17.095 11.1041 16.434 9.78205C15.966 9.92605 15.482 10.0001 15 10.0001C12.243 10.0001 10 7.75705 10 5.00005C10 4.74705 10.02 4.49605 10.059 4.24805C6.561 5.11505 4 8.26805 4 12.0001Z"
      />
      <path
        fill={fillColor}
        d="M21 6C21.5523 6 22 5.55228 22 5C22 4.44772 21.5523 4 21 4C20.4477 4 20 4.44772 20 5C20 5.55228 20.4477 6 21 6Z"
      />
    </svg>
  );
};

const Chevron = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"/>
      </g>
    </svg>
  )
}

const Done = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M9.00002 16.2001L4.80002 12.0001L3.40002 13.4001L9.00002 19.0001L21 7.0001L19.6 5.6001L9.00002 16.2001Z"/>
      </g>
    </svg>
  )
}

const Hamburger = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <rect x="1" y="5" width="22" height="2"/>
        <rect x="1" y="11" width="22" height="2"/>
        <rect x="1" y="17" width="22" height="2"/>
      </g>
    </svg>
  )
}

const Close = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12l7.293-7.293z"/>
      </g>
    </svg>
  )
}

const Mail = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"/>
      </g>
    </svg>
  )
}

const Phone = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"/>
      </g>
    </svg>
  )
}

const Substract = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M21 11.001H3V13.001H21V11.001Z"/>
      </g>
    </svg>
  )
}

const User = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.002 8C16.002 10.205 14.208 12 12.002 12C9.79701 12 8.00201 10.205 8.00201 8C8.00201 5.795 9.79601 4 12.002 4C14.208 4 16.002 5.795 16.002 8Z"/>
        <path d="M4.00201 19C4.00201 15.467 7.29201 13 12.002 13C16.713 13 20.002 15.467 20.002 19V20H4.00201V19Z"/>
      </g>
    </svg>
  )
}

const MeasurementArea = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M1 1H5V5H1V1Z"/>
        <path d="M7 2V4H10.3333V2H7Z"/>
        <path d="M13.6667 2V4H17V2H13.6667Z"/>
        <path d="M22 7H20V10.3333H22V7Z"/>
        <path d="M22 13.6667H20V17H22V13.6667Z"/>
        <path d="M17 22V20H13.6667V22H17Z"/>
        <path d="M10.3333 22V20H7V22H10.3333Z"/>
        <path d="M2 17H4V13.6667H2V17Z"/>
        <path d="M2 10.3333H4V7H2V10.3333Z"/>
        <path d="M5 19H1V23H5V19Z"/>
        <path d="M19 1H23V5H19V1Z"/>
        <path d="M23 19H19V23H23V19Z"/>
      </g>
    </svg>
  )
}

const MeasurementLength = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M1 1H5V5H1V1Z"/>
        <path d="M19 19H23V23H19V19Z"/>
        <path d="M6 6V13H8V9.41424L14.5858 16L11 16L11 18H18V11L16 11L16 14.5858L9.41418 8H13V6H6Z"/>
      </g>
    </svg>
  )
}

const MeasurementTilt = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.58582 20.0001H22V18.0001H16.9622C16.7463 15.1619 15.619 12.5785 13.8725 10.5419L18.7071 5.70718L17.2929 4.29297L1.58582 20.0001ZM12.4533 11.961L6.41424 18.0001H14.9552C14.7491 15.7133 13.8432 13.6284 12.4533 11.961Z"
        />
      </g>
    </svg>
  )
}


const Icons = {
  Add,
  ArrowDown,
  ArrowBackward,
  ArrowForward,
  Cookie,
  Chevron,
  Done,
  Hamburger,
  Close,
  Mail,
  Phone,
  Substract,
  User,
  MeasurementArea,
  MeasurementLength,
  MeasurementTilt
}

export default Icons
