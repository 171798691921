import React, { useRef, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useParallax } from "react-scroll-parallax";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Data
const KeyBenefitsData = [
  {
    leadTitle: "Innovatív technológia",
    title: "Légi LiDAR - Térinformatika",
    text: "Az általunk alkalmazott lézeres letapogató szenzor másodpercenként 1.300.000 impulzust képes kibocsájtani. A nagy hatékonyságú, speciális berendezésünknek köszönhetően felmérésünk és adatfeldolgozásunk következtében a valósággal közel azonos épületmodelleket tudunk előállítani 3 munkanapon belül.",
    parallaxTransition: { scale: [1.1, 1.4], translateX: [0, 5], rotateX: [-10, 10], rotateZ: [-5, 5] }
  },
  {
    leadTitle: "Költséghatékony megoldás",
    title: "Tetőadatok több épületre gyorsan, egyszerűen",
    text: "Több órás egyeztetés, utazás és bosszúság a helyszíni szemle miatt? Fordítsa ezt az időt a napelemes rendszerek tervezésére, ajánlatadásra! Szolgáltatásunk igénybevételével néhány kattintással, rövid időn belül akár több projektjéhez kapcsolódó tetőadatok birtokába kerülhet, így munkafolyamatait hatékonyabbá teheti.",
    parallaxTransition: { scale: [1.5, 1.05], rotateZ: [20, 0] }
  }
];

// Component
const KeyBenefits = () => {
  const images = useStaticQuery(graphql`
    query keybenefits {
      allFile(
        filter: {
          absolutePath: { regex: "/(keybenefits)/" }
          sourceInstanceName: { ne: "markdown" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                placeholder: BLURRED
                formats: WEBP
                breakpoints: [378, 672, 980, 1200, 1568]
              )
            }
            name
            sourceInstanceName
          }
        }
      }
    }
  `);
  const keybenefitsImg = images.allFile;

  return (
    <section className="--key-section">
      {KeyBenefitsData.map((keybenefit, index) => {
        return (
          <div
            key={index}
            className="--key-section__item"
          >
            <ParallaxImage
              imgObject={keybenefitsImg}
              index={index}
              transition={keybenefit.parallaxTransition}
            />
            
            <div className="--key-section__item__text-col">
              <div className="--key-section__item__text-col__heading">
                <h4>{keybenefit.leadTitle}</h4>
                <h2>{keybenefit.title}</h2>
              </div>
              <p>{keybenefit.text}</p>
            </div>
          </div>
        )
      })}
    </section>
  )
};


const ParallaxImage = ({imgObject, index, transition}) => {
  const containerRef = useRef();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(100);

  const parallax = useParallax({
    ...transition,
    startScroll: start,
    endScroll: end
  });

  // Set Start and end based on container
  useEffect(() => {
    setStart(containerRef.current.offsetTop - window.innerHeight)
    setEnd(containerRef.current.offsetTop + containerRef.current.clientHeight)
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className="--key-section__item__img-col"
    >
      <div ref={parallax.ref}>
          <GatsbyImage
            image={getImage(
              imgObject.edges[index].node.childrenImageSharp[0].gatsbyImageData
            )}
            loading={"lazy"}
            alt={imgObject.edges[index].node.name}
          />
      </div>
    </div>
  )
};

export default KeyBenefits;
