import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import Layout from "../components/Layout"
import { Seo } from "../components/Seo"
import USPSection from "../components/Sections/USP"
import KeyBenefits from "../components/Sections/KeyBenefits"
import Customers from "../components/Sections/Customers"
import DemoSteps from "../components/Sections/DemoSteps"
import Contact from "../components/Sections/Contact"
import Faq from "../components/Sections/Faq"


// INDEX
const Index = () => {
  return (
    <ParallaxProvider>
      <Layout>
        <Seo />
        <USPSection />
        <KeyBenefits />
        <Customers />
        <DemoSteps />
        <Contact />
        <Faq />
      </Layout>
    </ParallaxProvider>
  )
}

export default Index