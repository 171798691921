import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export const Seo = ({ title, description, author, url, keywords }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaTitle = title || data.site.siteMetadata.title
        const metaDescription = description || data.site.siteMetadata.description
        const metaAuthor = author || data.site.siteMetadata.author.name
        const metaUrl = url || data.site.siteMetadata.siteUrl
        const metaKeywords = keywords || data.site.siteMetadata.keywords
        return (
          <Helmet
            title={metaTitle}
            htmlAttributes={{
              lang: 'hu',
            }}
            meta={[
              {
                name: `author`,
                content: metaAuthor,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `keywords`,
                content: metaKeywords,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:image`,
                content: "https://www.solarvp.hu/og-image.jpg",
              },
            ]}
          />
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        author {
          name
        }
        title
        description
        siteUrl
        keywords
      }
    }
  }
`
